import { LoaderFunctionArgs, redirect } from "@remix-run/node"
import { Outlet } from "@remix-run/react"
import { isAuthorized } from "~/api/session/session.server"
import AuthSideView from "./ScreenComponents/AuthSideView"
import useUserInvalidate from "~/api/mutations/useUserQueryInvalidate"
import React from "react"
import { useMemoryStorage } from "~/context/memoryContainer"

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isUserAuthorized = await isAuthorized(request)
  if (isUserAuthorized) {
    return redirect("/dashboard")
  }
  return null
}

const AuthWrapperScreen = () => {
    const { invalidate, eraseQuery } = useUserInvalidate()
    const { flush } = useMemoryStorage("token")

    React.useEffect(() => {
      flush()
      invalidate()
      eraseQuery()
    }, [])

    return <div className="relative flex h-dvh justify-center overflow-hidden bg-gray-50 flex-row">
        <div className="flex flex-1 w-full lg:w-1/2 md:w-full h-full py-4 items-center justify-center flex-col">
          <div className="flex flex-col items-center justify-center relative max-sm:w-full max-sm:min-h-full">
            <Outlet />
          </div>
        </div>
        <div className="flex-1 hidden 2xl:flex w-1/2 min-h-full justify-center items-center bg-placeholder">
          <AuthSideView />
        </div>
    </div>
}

export default AuthWrapperScreen