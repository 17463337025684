import { useLocation } from "@remix-run/react"
import Login from '~/assets/auth/login.png'
import ClientSignup from "~/assets/auth/client-signup.png"
import ClientSignupElements from "~/assets/auth/client-signup.elements.png"
import StaffSignup from "~/assets/auth/staff.signup.png"
import StaffSignupElements from "~/assets/auth/staff.signup.elements.png"
import StaffCodeVerify from "~/assets/auth/staff.code-verify.png"
import StaffCodeVerifyElements from "~/assets/auth/staff.code-verify.elements.png"
import ClientCodeVerify from "~/assets/auth/client-code-verify.png"
import ForgetPass from "~/assets/auth/forgot-password.png"
import ForgetPassElements from "~/assets/auth/forgot-password.elements.png"
import SideScreenImage from "./SideScreenImage"

const AuthSideView = () => {
    const { pathname } = useLocation()

    const content = () => {
        switch (pathname) {
            case "/login": 
                return <SideScreenImage overlay={{source: Login, ancherPoint: "object-center"}} />
            case "/forget-pass": 
            case "/forget-pass/reset-pass":
                return <SideScreenImage src={ForgetPass} overlay={{source: ForgetPassElements, ancherPoint: "object-bottom"}}/>
            case "/client/signup": 
                return <SideScreenImage src={ClientSignup} overlay={{source: ClientSignupElements, ancherPoint: "object-bottom"}}/>
            case "/staff/signup": 
                return <SideScreenImage src={StaffSignup} overlay={{source: StaffSignupElements, ancherPoint: "object-bottom"}}/>
            case "/staff/code-verify": 
            case "/forget-pass/code-verify":
                return <SideScreenImage src={StaffCodeVerify} overlay={{source: StaffCodeVerifyElements, ancherPoint: "object-center"}}/>
            case "/client/code-verify": 
                return <SideScreenImage hasMirrorEffect={false} overlay={{source: ClientCodeVerify, ancherPoint: "object-center"}}/>
        }
    }

    return content()
}


export default AuthSideView;